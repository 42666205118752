<template>
    <v-container fluid>
        <h2>Log no Sistema</h2>
        <p></p>
        <v-card-titile>
            <v-row justify="center">
                <v-col>
                    <v-data-table :loading="loading" :items="items" :search="search" :headers="headers">
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="14">
                                    <v-text-field v-model="search" label="Pesquisar" class="mx-0"></v-text-field>
                                </v-col>
                                <v-col class="text-right">
                                    <Tab :current-item="headers" @refresh_atendimentos="get_notafiscal()" />
                                    <v-btn icon @click="get_notafiscal()"><v-icon>mdi-refresh</v-icon></v-btn>
                                    <Editar :currentItem="{}" />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:[`item.editar`]="{ item }">
                            <Editar :currentItem="item" />
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-titile>
    </v-container>
</template>
<script>
import api from '../../http';


export default {
    name:"log",
    components: {

    },
    data: () => ({
        loading: false,
        items: [],
        search: '',
        headers: [
            { text: "ID", sortable: true, value: "id" },
            { text: "Usuario", sortable: true, value: "" },
            { text: "Data de Modificação", data: true, value: "" },
            { text: "Como era antes", sortable: true, value: "" },
            { text: "Após Alteração", sortable: true, value: "" },
        ],
    }),
    methods: {
        get_listar() {
            this.loading = true;
            api("notas_fiscais/listar/")
                .then((response) => {
                    this.listar = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatar notas fiscais");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.get_listar()
    }
}
</script>